import Plot from 'react-plotly.js';
import BetaDensityPlot from './betadensityplot';


export default function Priors() {
  return (
    (<div className="grid w-full grid-cols-[1fr_2fr_3fr_1fr] gap-4 h-50">
      
      <div className="bg-gray-800 p-4 rounded shadow-md w-110">
        <pre className="font-mono text-sm leading-5">
          <code className="text-gray-300">
            <p>prior_control = pm.Beta("conv_control", mu=0.2, sigma=0.02)</p>
            <p>prior_test = pm.Beta("conv_test", mu=0.2, sigma=0.02)</p>
          </code>
        </pre>
      </div>

      {/* <div className="flex flex-col h-full bg-gray-900 text-white h-80">
        <h2 className="text-lg font-semibold px-4 py-2 bg-gray-800">Code Editor</h2>
        <div className="flex-1 overflow-auto p-4">
          <pre className="text-sm font-mono">
            <code className="language-javascript">
              {`function helloWorld() {
            console.log("Hello, world!");
          }
          
          work-helloWorld();`}
            </code>
          </pre>
        </div>
      </div> */}
      <div className="flex flex-col h-full bg-white w-96">
        <h2 className="text-lg font-semibold px-4 py-2 bg-gray-200">Prior Distributions</h2>
        <div className="flex-1 overflow-auto p-4">
          <BetaDensityPlot /  >
        </div>
      </div>
      <div className="flex flex-col h-full bg-white border-l border-gray-300 w-200">
        <div className="flex-1 overflow-auto p-4">
          <p className="text-xs font-mono text-gray-600">Reflecting Uncertainty: If you're quite uncertain about the mu of 0.2, you might choose a sigma that is a substantial fraction of this maximum. 
          For instance, a sigma of around 0.1 to 0.2 could reflect a reasonable level of uncertainty.</p>
          <p>Moderate Uncertainty: For moderate uncertainty, you might choose a sigma that is smaller, such as 0.05 to 0.1. 
            This would make the distribution less spread out (less flat), indicating that while you're uncertain about the mu of 0.2, 
            you don't believe it to be wildly different.
          </p>  
          <p>High Certainty: If you were quite certain about the mu of 0.2, you would choose a smaller sigma, such as 0.01 to 0.03. 
            This would concentrate the distribution more tightly around 0.2.
          </p>  
          <p>
            Contextual Consideration: The appropriate level of sigma also depends on the context of your problem and what the rate of 0.2 represents.
            If small deviations from 0.2 are significant in your context, you might lean towards a smaller sigma. 
            Conversely, if the rate is more flexible, a larger sigma could be appropriate.
          </p>
        </div>
      </div>
    </div>)
  );
}
