
export default function Delta() {
  return (
    (<div className="grid w-full grid-cols-[1fr_2fr_3fr_1fr] gap-4 h-40">
      
      <div className="bg-gray-800 p-4 rounded shadow-md w-96">
        <pre className="font-mono text-sm leading-5">
          <code className="text-gray-300">
            beta = np.beta(1,1)
          </code>
        </pre>
      </div>

      {/* <div className="flex flex-col h-full bg-gray-900 text-white h-80">
        <h2 className="text-lg font-semibold px-4 py-2 bg-gray-800">Code Editor</h2>
        <div className="flex-1 overflow-auto p-4">
          <pre className="text-sm font-mono">
            <code className="language-javascript">
              {`function helloWorld() {
            console.log("Hello, world!");
          }
          
          work-helloWorld();`}
            </code>
          </pre>
        </div>
      </div> */}
      <div className="flex flex-col h-full bg-white w-96">
        <h2 className="text-lg font-semibold px-4 py-2 bg-gray-200">Output</h2>
        <div className="flex-1 overflow-auto p-4">
          <pre className="text-sm font-mono">
            <code>Hello, world!</code>
          </pre>
        </div>
      </div>
      <div className="flex flex-col h-full bg-white border-l border-gray-300 w-96">
        <div className="flex-1 overflow-auto p-4">
          <p className="text-xs font-mono text-gray-600">This is a placeholder for additional information.</p>
        </div>
      </div>
    </div>)
  );
}
