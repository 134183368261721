
import React, { useState } from 'react';
// import { Button } from "src/@/components/ui/button"
import { Button } from "../ui/button"

const Hero = () => {
    const [inputValue, setInputValue] = useState('');
  
    const handleInputChange = (e) => {
      setInputValue(e.target.value);
    };
  
    const handleSubmit = () => {
      console.log('Input Value:', inputValue);
      // Add any additional logic or send data to the main app here
    };
  
  return (
    <main>
      {/* <section className="w-full py-4 bg-white shadow-lg">
        <div className="container px-4 md:px-6 flex justify-between items-center">
          <div className="flex items-center space-x-2">
            
            <div className="text-2xl font-bold text-gray-400">DS1</div>
          </div>
          <div className="space-x-4">
            <Button className="bg-blue-500 text-white">Sign Up</Button>
            <Button className="bg-transparent border border-blue-500 text-blue-500">Log In</Button>
          </div>
        </div>
      </section>    */}
    <section className="w-full py-12 md:py-24 lg:py-32 xl:py-48 bg-gradient-to-r from-green-400 to-blue-500" style={{height:'250px'}}>
      <div className="container px-4 md:px-6">
        <div className="flex flex-col items-center space-y-4 text-center">
          <div className="space-y-2">
            <h1 className="text-3xl font-bold tracking-tighter text-white sm:text-4xl md:text-5xl lg:text-6xl">
              Build smarter models faster
            </h1>
            <p className="mx-auto max-w-[700px] text-white md:text-xl">
            Assisted Data Science
            </p>
          </div>
          <div className="w-full max-w-sm space-y-2">
            <form className="flex space-x-2">
            </form>
           
          </div>
        </div>
      </div>
    </section>
    <section className="w-full py-12">
      <div className="container px-4 md:px-6">
        <div className="flex flex-col items-center text-center">
          <p className="mx-auto max-w-[700px] text-xl md:text-2xl">
          Build advanced data science models to solve real-world challenges.
          </p>
        </div>
      </div>
    </section>
  </main>
 );
};

export default Hero;

