import React, { useEffect, useState } from 'react';
import Plotly from 'plotly.js/lib/core';
import createPlotlyComponent from 'react-plotly.js/factory';
import * as math from 'mathjs';  // Use '*' to import all functions from mathjs


const Plot = createPlotlyComponent(Plotly);

const BetaDensityPlot = () => {
  // State to store Beta distribution data
  const [betaData, setBetaData] = useState([]);

  // Function to generate data for Beta distribution
  const generateBetaData = (alpha, beta, size) => {
    const data = [];
    for (let i = 0; i <= size; i++) {
      const x = i / size;
      const y =
        math.gamma(alpha + beta) /
        (math.gamma(alpha) * math.gamma(beta)) *
        Math.pow(x, alpha - 1) *
        Math.pow(1 - x, beta - 1);
      data.push({ x, y });
    }
    return data;
  };

  // Set parameters for Beta distribution
  const alpha = 2;
  const beta = 5;
  const size = 100;

  // Generate Beta distribution data on component mount
  useEffect(() => {
    const data = generateBetaData(alpha, beta, size);
    setBetaData(data);
  }, []);

  // Plot layout configuration
  const layout = {
    title: 'Beta Density Plot',
    xaxis: { title: 'X' },
    yaxis: { title: 'Density' },
  };

  return (
    <Plot
      data={[
        {
          x: betaData.map((point) => point.x),
          y: betaData.map((point) => point.y),
          type: 'scatter',
          mode: 'lines',
          line: { color: '#34d399' },
          fill: 'tozeroy',
          fillcolor: 'rgba(52, 211, 153, 0.3)',
        },
      ]}
      layout={layout}
    />
  );
};

export default BetaDensityPlot;
