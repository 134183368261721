
import React, { useState } from 'react';
// import { Button } from "src/@/components/ui/button"
import { Button } from "./ui/button"
import Pricing from './home/pricing'
import Hero from './home/hero';

export default function Home() {
  return (
    <div>
      <Hero />
      <Pricing />
    </div>  
 );
}

