import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Home from './@/components/home';
import Work from './@/components/work';
import './index.css'; 

function App() {
  const [message, setMessage] = useState('');

  useEffect(() => {
    // fetch('/api/train')
    //   .then((response) => response.text())
    //   .then((data) => {
    //     setMessage(data);
    //   })
    //   .catch((error) => {
    //     console.error('Error:', error);
    //   });
  }, []);

  return (
    <main>
       {/* Router for handling routes */}
       <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/work" element={<Work />} />
        </Routes>
      </Router>
     
    </main>
  );
}

export default App;





