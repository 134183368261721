
import React, { useState } from 'react';
// import { Button } from "src/@/components/ui/button"
import { Button } from "../ui/button"

const WorkHeader = () => {
    const [inputValue, setInputValue] = useState('');
  
    const handleInputChange = (e) => {
      setInputValue(e.target.value);
    };
  
    const handleSubmit = () => {
      console.log('Input Value:', inputValue);
      // Add any additional logic or send data to the main app here
    };
  
  return (
    <main>
      {/* <section className="w-full py-4 bg-white shadow-lg">
        <div className="container px-4 md:px-6 flex justify-between items-center">
          <div className="flex items-center space-x-2">
            
            <div className="text-2xl font-bold text-gray-400">DS1</div>
          </div>
          <div className="space-x-4">
            <button className="bg-blue-500 text-white">Sign Up</button>
            <button className="bg-transparent border border-blue-500 text-blue-500">Log In</button>
          </div>
        </div>
      </section>    */}
    <section className="w-full bg-gradient-to-r from-green-400 to-blue-500" style={{height:'50px'}}>
      
    </section>
  </main>
 );
};

export default WorkHeader;

