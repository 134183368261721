
import { RadioGroup, RadioGroupItem } from "../../ui/radio-group"
import { Button } from "../../ui/button"
import { Label } from "../../ui/label"

export default function Metric() {
  return (
    <div className="flex justify-between items-center mb-4">
      <h1 className="text-lg font-medium">Metric Type</h1>
      <RadioGroup defaultValue="comfortable">
      <div className="flex items-center space-x-2">
        <RadioGroupItem value="default" id="r1" />
        <Label htmlFor="r1">Discrete (i.e clicks, conversions)</Label>
      </div>
      <div className="flex items-center space-x-2">
        <RadioGroupItem value="comfortable" id="r2" />
        <Label htmlFor="r2">Continuous (i.e revenue, time, quantity)</Label>
      </div>
      </RadioGroup>
      <Button
        className="px-2 py-1 bg-gray-800 text-white rounded flex items-center space-x-2 text-sm"
        type="button">
        <DownloadIcon className="w-4 h-4" />
        <span>Download</span>
      </Button>
    </div>
  );
}

function DownloadIcon(props) {
  return (
    (<svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round">
      <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
      <polyline points="7 10 12 15 17 10" />
      <line x1="12" x2="12" y1="15" y2="3" />
    </svg>)
  );
}
